import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import './css/index.css';
import Webpage from './components/Webpage';
import ScrollToTop from './components/ScrollToTop';
import 'jquery/dist/jquery';


ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter basename="/">
         <ScrollToTop />
         <Webpage />
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);