import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Webpage.css';

import Navigation from './Navigation';
import Main from './Main';
import AboutUs from './AboutUs';
import Footer from './Footer';

class Webpage extends Component {

   constructor() {
      super();
      this.state = {
         currentPage: "/"
      }
   }

   render() {
      return (
      <div className="contentContainer">
         <Navigation />
         <Main />
         <AboutUs />
         <Footer />
      </div>
      );
   }
}

export default Webpage;