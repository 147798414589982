import React from 'react';
import Container from 'react-bootstrap/Container';

function Contact() {

   return (
      <main>
         <header className="banner bodyContent">
            <div className="catchphrase">
               <h1>Don't be shy. Connect with us today!</h1>
            </div>
            <div className="bannerText">
               <p>We're excited to help you with your digital design needs, but we are a small, student-run shop.
                  That means that most of our staff are working double to further their education while increasing
                  your digital footprint. Please give us 1&ndash;3 days business days to respond to your e-mails
                  and find the right design solution for you!
               </p>
            </div>
         </header>
         <Container className="bodyContent">
            <h2>Contact</h2>
            <hr />
            <Container className="contactInfo">
               <section>
                  <h3>email</h3>
                  <a href="mailto:hello@localdesigns.com">hello@localdesigns.com</a>
               </section>
               <section>
                  <h3>instagram</h3>
                  <a href="https://www.instagram.com/yourlocaldesigns/" target="_blank" rel="noopener noreferrer">@yourlocaldesigns</a>
               </section>
            </Container>
         </Container>
      </main>
   );
}

export default Contact;