import React from 'react';
import Container from 'react-bootstrap/Container';

function PageNotFound() {

   return (
      <main>
         <header className="banner bodyContent">
            <div className="catchphrase">
               <h1>Hmm... Something's not right...</h1>
            </div>
            <div className="bannerText">
               <p>
                  Looks like we've come across the infamous <strong>"Error 404"</strong>. Why don't
                  you try one of our links in the navigation menu, or click the Local Designs logo in
                  the top left to go back to our homepage.
               </p>
            </div>
         </header>
         <Container className="bodyContent">
         </Container>
      </main>
   );
}

export default PageNotFound;