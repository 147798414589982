import React from 'react';
import '../css/Photography.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';

function Photography() {

   return (
      <main>
         <header className="banner bodyContent">
            <div className="catchphrase">
               <h1>Glitz. Glamour. Goods.</h1>
            </div>
            <div className="bannerText">
               <p>
                  Everything from your staples to your new arrivals; products, services,
                  and physical location can and should be photographed for digital or print publication.
               </p>
            </div>
         </header>
         <Container className="bodyContent">
            <div className="serviceDescription">
               <h3>Photography</h3>
               <p>
                  Whether you are selling muffins, mixing paints, or changing tires,
                  we can provide high-resolution photography services of your products, services,
                  and workspace to augment your custom-made website.
               </p>
               <h4 className="PH-h4">Expertly Optimized</h4>
               <p>
                  Partnering with color management experts, our photography team here at Local Designs
                  is committed to delivering photos for your business in the best format possible.
                  You can be sure that your photographs will be optimized in color, resolution,
                  and quality for your digital and print needs.
               </p>
            </div>
            <Container className="callToAction">
               <h3>Ready for your own website?</h3>
               <Link to="contact"><button type="button" className="PH-button">Start Creating</button></Link>
               <br />
            </Container>
            <div className="otherServices">
               <h3>Looking for something else?</h3>
               <Container className="cards">
                  <Card>
                     <Card.Body>
                        <Card.Title>Website Design</Card.Title>
                        <Card.Text>
                           It's no longer enough to be just a page on Facebook or Instagram.
                           Establish your presence in today's world with custom-made websites for your business,
                           community group, or portfolio. 
                        </Card.Text>
                        <Card.Link><Link exact to="/website-design">Explore website design services <TiArrowRight /></Link></Card.Link>
                     </Card.Body>
                  </Card>
                  <Card>
                     <Card.Body>
                        <Card.Title>Graphic Design</Card.Title>
                        <Card.Text>
                           Starting a new business? Hosting an event? Having a sale? Our in-house
                           graphic designers can make both digital and physical products, including
                           logos, brochures, fliers, and advertisements. When necessary,
                           these services include original photography from our own team.
                        </Card.Text>
                        <Card.Link><Link exact to="/graphic-design">Explore graphic design services <TiArrowRight /></Link></Card.Link>
                     </Card.Body>
                  </Card>
               </Container>
            </div>
         </Container>
      </main>
   );
}

export default Photography;