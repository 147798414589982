import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import WebDesign from './WebDesign';
import GraphicDesign from './GraphicDesign';
import Photography from './Photography';
import Contact from './Contact';
import PageNotFound from './PageNotFound';

const Main = () => {
   return (
      <Switch>
         <Route exact path="/">
            <Home />
         </Route>
         <Route exact path="/website-design">
            <WebDesign />
         </Route>
         <Route exact path="/graphic-design">
            <GraphicDesign />
         </Route>
         <Route exact path="/photography">
            <Photography />
         </Route>
         <Route exact path="/contact">
            <Contact />
         </Route>
         <Route path="*">
            <PageNotFound />
         </Route>
      </Switch>
  );
}

export default Main;