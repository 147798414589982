import React from 'react';
import Container from 'react-bootstrap/Container';

function AboutUs() {

   return (
      <Container className="about bodyContent">
         <h2>About us</h2>
         <hr />
         <p>
            Local Designs is a <strong>veteran-owned</strong> and <strong>student-led</strong> digital 
            media design shop based out of <strong>San Luis Obispo, California</strong>. 
            We apply the latest design methods and principles to create custom websites and 
            graphics for your unique needs.
         </p>
         <p>
            Please <a href="/contact" alt="Contact us">contact us</a> for any questions and  
            free individualized pricing quotes!
         </p>
      </Container>
   );
}

export default AboutUs;