import React, { Component } from 'react';

class Footer extends Component {

   render() {
      return (
         <footer>
            <div className="footerLinks">
               <h3>Local Designs</h3>
               <h4>San Luis Obispo, California</h4>
               <a href="mailto:hello@localdesigns.com">hello@localdesigns.com</a>
               <ul className="footerLinks">
                  <h4 className="footerServices">Services</h4>
                  <li><a href="/website-design">Website design</a></li>
                  <li><a href="/graphic-design">Graphic design</a></li>
                  <li><a href="/photography">Photography</a></li>
                  <li><a href="/">Home</a></li>
               </ul>
            </div>
            <div className="copyright">
               <span>Local Designs &copy; 2020</span>
            </div>
         </footer>
      );
   }
}

export default Footer;