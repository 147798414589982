import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import horizontalLogo from '../assets/local-designs-horizontal.svg';

function Navigation() {

   return(
         <div className="nav">
            <Navbar className="top-nav" expand="md">
            <Navbar.Brand href="/"><img src={horizontalLogo} alt="Local Designs Homepage" className="navimg"/></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="top-nav justify-content-end">
                     <Nav.Item>
                        <NavLink exact to="/" activeClassName="current-page">Home</NavLink>
                     </Nav.Item>
                     <Nav.Item>
                        <NavLink exact to="/website-design" activeClassName="current-page">Web Design</NavLink>
                     </Nav.Item>
                     <Nav.Item>
                        <NavLink exact to="/graphic-design" activeClassName="current-page">Graphic Design</NavLink>
                     </Nav.Item>
                     <Nav.Item>
                        <NavLink exact to="/photography" activeClassName="current-page">Photography</NavLink>
                     </Nav.Item>
                     <Nav.Item>
                        <NavLink exact to="/contact" activeClassName="current-page">Contact Us</NavLink>
                     </Nav.Item>
                  </Nav>
            </Navbar.Collapse>
            </Navbar>
         </div>
   );
}

export default Navigation;