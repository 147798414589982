import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import webDesignImg from '../assets/website-builders.svg';
import graphicDesignImg from '../assets/black-friday-poster-sample.png';
import photographyImg from '../assets/kjb-sportwear-1600.jpg';

import { TiArrowRight } from 'react-icons/ti';

function Home() {

   let sections = [
      {
         title: "Website Design",
         subtitle: "Custom-made web design and site management specifically for your needs.",
         href: "/website-design",
         image: webDesignImg,
         img_alt: "Two cartoon people building a 3D webpage together",
         c2a: "Learn more about our web design"
      },
      {
         title: "Graphic Design",
         subtitle: "Flyers, posters, and other graphics for your digital and printed publicity.",
         href: "/graphic-design",
         image: graphicDesignImg,
         img_alt: "Black Friday sale poster sample",
         c2a: "Learn more about our graphic design"
      },
      {
         title: "Photography",
         subtitle: "At-location retail images, product photography, special events, and more.",
         href: "/photography",
         image: photographyImg,
         img_alt: "Man pulls ink through screen onto a t-shirt",
         c2a: "Learn more about our photography"
      }
   ]

   return (
      <main>
         <header className="banner bodyContent">
            <div className="catchphrase">
               <h1>Connect with your community and beyond.</h1>
            </div>
            <div className="bannerText">
               <p>Local Designs creates an online platform for businesses 
                  committed to serving their communities. We provide 
                  individualized website design, development, and management 
                  along with other services to help your digital outreach.</p>
            </div>
         </header>
         <Container className="bodyContent">
            <h2>Services</h2>
            <hr />
            <Container className="previewService">
               {
                  sections.map(curr => (
                     <section key={curr.title}>
                        <Image src={curr.image} alt={curr.img_alt} />
                        <section className="text">
                           <h3>{curr.title}</h3>
                           <p>{curr.subtitle}</p>
                           <Link exact to={curr.href}>{curr.c2a} <TiArrowRight /></Link>
                        </section>
                     </section>
                  ))
               }
            </Container>
         </Container>
      </main>
   );
}

export default Home;