import React from 'react';
import '../css/WebDesign.css';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { TiArrowRight } from 'react-icons/ti';

function WebDesign() {

   return (
      <main>
         <header className="banner bodyContent">
            <div className="catchphrase">
               <h1>This isn't your MySpace page.</h1>
            </div>
            <div className="bannerText">
               <p>
                  In today’s world, if you don’t exist online, it’s like you don’t exist at all.
                  Whether it’s for your small business, neighborhood group, or personal résumé,
                  we have your back. Browse our design, development, and management services—or if you
                  don’t know where to start, contact us and we’ll be happy to help!
               </p>
            </div>
         </header>
         <Container className="bodyContent">
            <div className="serviceDescription">
               <h3>Website Design</h3>
               <p>
                  From start to finish and beyond, we will design, build, and manage your website.
                  Thanks to our home-grown graphics and photography team, you can be sure that no
                  one else has a website like yours. Our team combines your requirements with the
                  latest engineering practices to give you a reliable, functional, and completely unique website.
               </p>
               <h4 className="WD-h4">No One Left Behind</h4>
               <p>
                  We strongly believe that disabilities shouldn’t restrict what a person can and can’t do,
                  so we take extra care to ensure that your website will be accessible to those with
                  disabilities (ADA compliance) without sacrificing quality.
               </p>
            </div>
            <div className="serviceDescription">
               <h3>Website Management</h3>
               <p>
                  Once you approve of your website, we’ll take care of publishing, marketing,
                  and keeping it on the web. We’ll maintain it daily and deal with any problems
                  that arise. When it comes time to change (as everything must eventually),
                  just give us a holler and we’ll be happy to make whatever updates you need!
               </p>
               <h4 className="WD-h4">Be Found</h4>
               <p>
                  Our Search Engine Optimization (SEO) experts work hard to elevate your website to
                  the top of the results page. Because the markets (and algorithms) change, this is
                  something we constantly update to keep your website relevant.
               </p>
            </div>
            <Container className="callToAction">
               <h3>Ready for your own website?</h3>
               <Link to="/contact"><button type="button" className="WD-button">Start Creating</button></Link>
               <br />
            </Container>
            <div className="otherServices">
               <h3>Looking for something else?</h3>
               <Container className="cards">
                  <Card>
                     <Card.Body>
                        <Card.Title>Graphic Design</Card.Title>
                        <Card.Text>
                           Starting a new business? Hosting an event? Having a sale? Our in-house
                           graphic designers can make both digital and physical products, including
                           logos, brochures, fliers, and advertisements. When necessary,
                           these services include original photography from our own team.
                        </Card.Text>
                        <Card.Link><Link exact to="/graphic-design">Explore graphic design services <TiArrowRight /></Link></Card.Link>
                     </Card.Body>
                  </Card>
                  <Card>
                     <Card.Body>
                        <Card.Title>Photography</Card.Title>
                        <Card.Text>
                           Stand-alone photography services are available for events, products,
                           professional headshots, and portraits. Get in touch with us today!
                        </Card.Text>
                        <Card.Link><Link exact to="/photography">Explore photography services <TiArrowRight /></Link></Card.Link>
                     </Card.Body> 
                  </Card>
               </Container>
            </div>
         </Container>
      </main>
   );
}

export default WebDesign;