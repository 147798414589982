import React from 'react';
import '../css/GraphicDesign.css';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { TiArrowRight } from 'react-icons/ti';

function GraphicDesign() {

   return (
      <main>
         <header className="banner bodyContent">
            <div className="catchphrase">
               <h1>Making graphics doesn't have to be graphic.</h1>
            </div>
            <div className="bannerText">
               <p>
                  Starting a new business? Hosting an event? Having a sale? Let us handle the visuals,
                  from digital to physical products, including logos, brochures, fliers, and advertisements.
               </p>
            </div>
         </header>
         <Container className="bodyContent">
            <div className="serviceDescription">
               <h3>Graphic Design</h3>
               <p>
                  If you're saying something, you probably want to be heard. Our graphic design team can
                  help you captivate people by visually communicating your message. While these graphics
                  can be used on your locally designed custom website, our team is also formally trained in
                  reproducing your graphics on printed media.
               </p>
               <h4 className="GD-h4">For Your Audience</h4>
               <p>
                  <i>How</i> you communicate depends on <i>who</i> you are trying to communicate with.
                  Our designs are always tailored to your target audience and your brand. We strongly
                  believe that disabilities shouldn’t restrict what a person can and can’t do,
                  so we take extra care to ensure that the designs (as with our websites) we make for
                  you will be accessible to those with visual impairments and disabilities.
               </p>
               <h4 className="GD-h4">Digital and Print</h4>
               <p>
                  Our graphic design services are first and foremost for use on the website we make for you.
                  And while the digital realm has certain rules and guidelines, the physical realm has its own.
                  Unfortunately, the office printer won't always be good enough&mdash;but here at Local Designs,
                  we're experts at making it look good on screen and paper.
               </p>
            </div>
            <Container className="callToAction">
               <h3>Ready for your own website?</h3>
               <Link to="/contact"><button type="button" className="GD-button">Start Creating</button></Link>
               <br />
            </Container>
            <div className="otherServices">
               <h3>Looking for something else?</h3>
               <Container className="cards">
                  <Card>
                     <Card.Body>
                        <Card.Title>Website Design</Card.Title>
                        <Card.Text>iou
                           It's no longer enough to be just a page on Facebook or Instagram.
                           Establish your presence in today's world with custom-made websites for your business,
                           community group, or portfolio. 
                        </Card.Text>
                        <Card.Link><Link exact to="/website-design">Explore website design services <TiArrowRight /></Link></Card.Link>
                     </Card.Body>
                  </Card>
                  <Card>
                     <Card.Body>
                        <Card.Title>Photography</Card.Title>
                        <Card.Text>
                           Stand-alone photography services are available for events, products,
                           professional headshots, and portraits. Get in touch with us today!
                        </Card.Text>
                        <Card.Link><Link exact to="/photography">Explore photography services <TiArrowRight /></Link></Card.Link>
                     </Card.Body>
                  </Card>
               </Container>
            </div>
         </Container>
      </main>
   );
}

export default GraphicDesign;